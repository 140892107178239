<template>
  <v-row class="mt-2 mb-1">
    <div v-if="$vuetify.breakpoint.mdAndUp" class="mr-2 ml-7">
      <dateTimePicker
        title="from"
        v-model="fromTime"
        :fieldAttrInput="{ class: '', style: 'max-width:' + textWidth + 'px' ,id:'fromRangePicker'}"
        :maxDate="0"
      ></dateTimePicker>
    </div>
    <div class="mr-2" v-if="$vuetify.breakpoint.mdAndUp">
      <dateTimePicker
        title="to"
        v-model="untilTime"
        :fieldAttrInput="{ class: '', style: 'max-width:' + textWidth + 'px' ,id:'toRangePicker'}"
        :maxDate="0"
      ></dateTimePicker>
    </div>
    <div class="mr-2" v-if="$vuetify.breakpoint.mdAndUp">
      <primaryButton
        type="button"
        @click="searchRange()"
        label="Search Range"
        icon="mdi-magnify"
        color="primary"
        :disabled="disabled"
        :loading="loading"
        shrink="lgAndDown"
      ></primaryButton>
    </div>
    <associatedOption
      type="select"
      :fieldAttrInput="{
        style: 'max-width:140px',
        id: 'timepickerSelectOption',
       
      }"
      :fieldAttributes="{...currentTable['timepickerSelectOption'], ignoreEmptyAdd:true}"
      v-model="period"
      :hideDetails="true"
    ></associatedOption>
  </v-row>
</template>
<script>
import dateTimePicker from "@/commonComponents/legacyDateTimePicker.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
import associatedOption from "@/commonComponents/associatedOption.vue";
export default {
  props: ["currentTable"],
  components: { dateTimePicker, primaryButton, associatedOption },
  data() {
    return {
      //period: this.currentTable["timepickerSelectOption"].value,
      disabled: false,
      loading: false,
      triggerTime: 0,
    };
  },
  computed: {
    textWidth() {
      let textWidth = 150;
      if (this.$vuetify.breakpoint.mdAndDown) {
        textWidth = 100;
      }
      if (this.$vuetify.breakpoint.smAndDown) {
        textWidth = 50;
      }
      return textWidth;
    },
    period: {
      get() {
        return this.currentTable["timepickerSelectOption"].value;
      },
      set(newVal) {
        let currentTable = this.currentTable;
        this.$set(currentTable.timepickerSelectOption, "value", newVal);
        this.$store.commit("tableChanged", currentTable);
        if (newVal !== "custom") {
          this.getRange(newVal);
        }
      },
    },

    url() {
      let url = "";
      if (typeof this.currentTable["tableUrl"] != "undefined") {
        url = this.currentTable["tableUrl"];
      }
      return url;
    },

    rangeSearchIndex() {
      let rangeSearchIndex = "";
      if (typeof this.currentTable["rangeSearchIndex"] != "undefined") {
        rangeSearchIndex = this.currentTable["rangeSearchIndex"];
      }
      return rangeSearchIndex;
    },
    id() {
      let id = "dataTable";
      if (typeof this.currentTable["id"] != "undefined") {
        id = this.currentTable["id"];
      }
      return id;
    },
    timepicker() {
      let timepicker = "false";
      if (typeof this.currentTable["timepicker"] != "undefined") {
        timepicker = this.currentTable["timepicker"];
      }
      return timepicker;
    },
    fromTime: {
      get() {
        let fromTime = 0;
        if (typeof this.currentTable["fromTime"] != "undefined") {
          fromTime = this.currentTable["fromTime"];
        }
        return fromTime;
      },
      set(val) {
        let currentTable = this.currentTable;
        this.$set(currentTable, "fromTime", val);
        this.$set(currentTable, "dateChange", true);
        this.$set(currentTable.timepickerSelectOption, "value", "custom");
        this.$store.commit("tableChanged", currentTable);
      },
    },
    untilTime: {
      get() {
        let untilTime = 0;
        if (
          typeof this.currentTable["untilTime"] != "undefined" &&
          this.currentTable["untilTime"] !== ""
        ) {
          untilTime = this.currentTable["untilTime"];
        }
        return untilTime;
      },
      set(val) {
        let currentTable = this.currentTable;
        this.$set(currentTable, "untilTime", val);
        this.$set(currentTable, "dateChange", true);
        this.$set(currentTable.timepickerSelectOption, "value", "custom");
        this.$store.commit("tableChanged", currentTable);
      },
    },
    mindate() {
      let mindate = "";
      if (typeof this.currentTable["mindate"] != "undefined") {
        mindate = this.currentTable["mindate"];
      }
      return mindate;
    },
    maxDate() {
      let maxDate = "";
      if (typeof this.currentTable["maxDate"] != "undefined") {
        maxDate = this.currentTable["maxDate"];
      }
      return maxDate;
    },
  },
  methods: {
    searchRange() {
      this.$set(this.currentTable, "dateChange", false);
      let triggerTime = new Date();
      this.$set(this.currentTable, "triggerTime", triggerTime);
      this.triggerTime = triggerTime;
      this.disabled = true;
      this.loading = true;
      // showLoading();
      // send a request to the backend to get the data for the given range
      // caller search range caller

      // var tableId = this.id;
      var uri = this.url;
      console.log(uri);
      var rangeSearchIndex = this.rangeSearchIndex;
      var from = this.fromTime;
      // include time untill the end of the day

      //console.log(this.untilTime);
      var until = parseInt(this.untilTime);
      if (this.timepicker === "false") {
        // search the whole day
        until = until + 86400;
      } else {
        // search the whole minute
        until = until + 60;
      }

      var options = {
        from: from,
        until: until,
        range: this.period,
        rangeSearchIndex: rangeSearchIndex,
        function: "searchRange",
        requestType: "ajax",
      };
      //  var ajaxOptions = $rangeContainer.attr("data-ajaxOptions");
      // if (typeof ajaxOptions != "undefined") {
      //  // ajaxOptions = $.parseJSON(ajaxOptions);
      //   options = { ...options, ...ajaxOptions };
      // }

      let caller = this;
      this.frameworkAxiosRequest({
        method: 'post',
        url: uri,
        data: options,
      })
          .then(function (response) {
          // console.log(response.data.result);
          let currentTable = caller.currentTable;
          if(typeof  response.data.result!='undefined'){
            caller.$set(currentTable, "data", response.data.result.table.data);
          }
          if (typeof  response.data.result!='undefined' && typeof response.data.result.table.reloadContent != "undefined") {
            caller.$set(
              currentTable,
              "reloadContent",
              response.data.result.table.reloadContent
            );
          }
          caller.$store.commit("tableChanged", currentTable);
          caller.disabled = false;
          caller.loading = false;
        })
        .catch(function (response) {
          console.log(response);
        });
    },

    getRange(rangeValue) {
      let untilTime = new Date().getTime() / 1000;
      let currentTable = this.currentTable;
      this.$set(currentTable, "untilTime", untilTime);

      let date = new Date();
      let intRangeValue = Number(rangeValue);
      if (!Number.isInteger(intRangeValue)) {
        var hoursTime = date.getHours() - Math.floor(intRangeValue * 24);
        date.setHours(hoursTime);
      } else {
        if (rangeValue === "0") {
          //today!
          date.setHours(0, 0, 0, 0);
        } else {
          //custom!
          date.setDate(date.getDate() - rangeValue);
        }
      }
      // console.log(rangeValue);
      let fromTime = Math.floor(date.getTime() / 1000);

      this.$set(currentTable, "fromTime", fromTime);

      this.$store.commit("tableChanged", currentTable);

      this.searchRange();
      // get the selected range and applied to the from picker and then search for this range
      // Arguments
      // caller the range selector
      // reload not implemented
      // called by statistics.statistics.php,msisdninfo.diametergx.php, msisdninfo.diametergy.php, msisdninfo.radius.php,statistics.statistics.php,systemStatus.alerts.php,systemStatus.alertsStatistics.php, systemUsage.recentEvents.php, testing.previousTests.php, resetCounter
      //var $caller = $(caller);
      // $caller.prop("disabled", true);
      // var rangeValue = $caller.val();
      // var timepicker = this.timepicker;
      // //var dateFormat="DD MMM YY H:mm";
      // var dateFormat = "DD.MM.YY HH:mm";
      // var $rangeContainer = $caller.closest('.rangeContainer');
      // var $dateUntil = $rangeContainer.find('.dateUntil');
      // $dateUntil.attr("data-generated", "false");
      // var $dateFrom = $rangeContainer.find('.dateFrom');
      // $dateFrom.attr("data-generated", "false");
      // var $updateInterval = $rangeContainer.find('.updateInterval');
      // if (typeof $updateInterval != "undefined") {
      //   if (rangeValue != 0) {
      //     // $updateInterval.attr('disabled','disabled');
      //     //$updateInterval.val(0);
      //     //var $updateInterval=$datepickerfield.closest(".rangeContainer").find(".updateInterval");
      //     if ($updateInterval.length > 0) {
      //       $updateInterval.val(0);
      //       //resetUpdateInterval($updateInterval);
      //       $periodSelector = $rangeContainer.find(".periodSelector");
      //       resetCounter($rangeContainer, $periodSelector, $updateInterval);
      //     }
      //   }/*else {
      //   $updateInterval.removeAttr('disabled');
      //   }*/
      // }

      // var date = new Date();
      // if (typeof $dateUntil.attr('data-timepicker') != "undefined" && $dateUntil.attr('data-timepicker') == "false") {
      //   timepicker = false;
      //   //dateFormat="DD MMM YY";
      //   dateFormat = "DD.MM.YY";
      // }
      // var formatedDate = moment(date).format(dateFormat);
      // $dateUntil.datetimepicker({ value: formatedDate });

      // if (rangeValue != 0) {
      //   var date = new Date();
      //   if (!isInt(rangeValue)) {
      //     var hoursTime = (date.getHours() - Math.floor((rangeValue * 24)));
      //     date.setHours(hoursTime);
      //   } else {
      //     date.setDate(date.getDate() - rangeValue);
      //   }

      //   if (typeof $dateFrom.attr('data-timepicker') != "undefined" && $dateFrom.attr('data-timepicker') == "false") {
      //     timepicker = false;
      //     // dateFormat="DD MMM YY";
      //     dateFormat = "DD.MM.YY";
      //   }
      //   var formatedDate = moment(date).format(dateFormat);

      //   $dateFrom.datetimepicker({
      //     value: formatedDate, onChangeDateTime: function (current_time, $input) {
      //       //console.log(current_time);
      //       var time = current_time.getTime() / 1000;
      //       setHiddenInputTime(time, $input);
      //       /*var $hiddenInput= $input.closest('.datepickerwraper').find('input[name="'+$input.attr('name')+'Result"]');

      //     if ($hiddenInput.length!=0){
      //       $hiddenInput.val(time);
      //     }else {
      //       $input.closest('.datepickerwraper').append("<input type=hidden name='"+$input.attr('name')+"Result' value='"+time+"'></span>");
      //     }*/
      //     }
      //   });
      // }
      // setTimeout(function () { searchRange(caller, reload); }, 500);
    },
    // searchRangeCallback($tableSelector, $overlay, caller) {
    //   // a callback function after getting the search range content to fade out the loadding and add the rows to the table
    //   // Arguments
    //   // $tableSelector the selector of the table where the data will be added
    //   // $overlay the loading overlay covering the table
    //   // called by searchRangeCallback
    //   hideLoading();
    //   return function (data) {
    //     var table = $tableSelector.data("dataTable");
    //     table.clear();
    //     $overlay.fadeOut();
    //     $tableSelector.append($(data).find(".tableLoadedScript"));
    //     addrows(data, $tableSelector, true);
    //     var $caller = $(caller);
    //     $caller.prop("disabled", false);
    //   };
    // },
  },
};
</script>
